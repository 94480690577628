<template>
  <div class="not-found">
    <page-title class="page-title">Seite nicht gefunden</page-title>
    <v-page>
      <article class="text hyphenate">
        <router-link :to="{ name: 'Home' }">Zur Homepage</router-link>
      </article>
    </v-page>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle'
import VPage from '@/components/Page'

export default {
  name: 'NotFound',
  components: {
    PageTitle,
    VPage,
  },
}
</script>

<style scoped>
.page-title {
  height: min(320px, 50vh, 70vw);
}
</style>
